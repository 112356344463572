<template>
  <footer>
    <nav>
      <ul>
        <li v-if="this.$route.name != '403'">
          <router-link class="go_to functional" @click.prevent :to="{path:'/select'}"><span></span>
          <span class="description">Go To</span></router-link>
        </li>
        <li :style="this.$route.name == '403' ? {margin: '0 auto', float: 'none'} : null">
          <router-link class="home functional" @click.prevent :to="{path:'/'}"><span></span>
          <span class="description">Home</span></router-link>
        </li>
        <li v-if="this.$route.name != '403'">
          <a class="messages functional" @click="$emit('disabledWindow')">
            <span></span>
            <span class="description">Messages</span>
            <div class="coming_soon_indicator">coming soon</div>
          </a>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'footerMenu',
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
  footer{
    // z-index:4;
    height: 72px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0px -2px 8px 2px rgba(0, 0, 0, 0.06);
    nav{
      ul{
        margin: 0;
        padding: 0;
        li{
          height: 72px;
          list-style-type: none;
          float: left;
          width: 33%;
          a{
            height: 72px;
            display: block;
            text-decoration: none;
            color: #787878;
            font-weight: 400;
            text-align: center;
            line-height: 72px;
            :nth-child(1){
              color: #004d4c;
            }
            span:first-child{
              display: inline-block;
              background-repeat: no-repeat;
              background-position: center;
              height: 40px;
              width: 40px;
              background-size: contain;
              margin-right: 15px;
              position: relative;
              top: 13.5px;
              
            }
          }
          a:hover:not(::last-child), a.active:not(::last-child){
            color: #006C3E;
          }
          .go_to{
            span:first-child{
              background-image: url(../../../public/img/goto_line.svg);
            }
          }
          .go_to:hover, .go_to.active{
            span:first-child{
              background-image: url(../../../public/img/goto_green.svg);
            }
          }
          .home{
            span:first-child{
              background-image: url(../../../public/img/bopus/bopus_hat_only_grey.svg);
            }
          }
          .home:hover, .home.active{
            span:first-child{
              background-image: url(../../../public/img/bopus/bopus_hat_only_green.svg);
            }
          }
          .messages{
            opacity: .5;
            position: relative;
            span:first-child{
              background-image: url(../../../public/img/no_message.svg);
            }
            .coming_soon_indicator{
              position: absolute;
              height: 20px;
              right: calc(50% - 105px);
              top: 5px;
              background-color: rgba(0, 108, 62, 0.15);
              color: #006C3E;
              font-size: 9px;
              line-height: 20px;
              padding: 0px 5px;
              border-radius: 3px;
              text-transform: uppercase;
              font-weight: 500;
            }
          }
          // .messages:hover, .messages.active{
          //   span:first-child{
          //     background-image: url(../../../public/img/nomess_green.svg);
          //   }
          // }

        }
      }
    }
  }
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   footer{
  //     height: 169px;
  //     nav{
  //       ul{
  //         height: 169px;
  //         display: flex;
  //         flex-direction: row;
  //         align-items: center;
  //         li{
  //           height: 94px;
  //           a{
  //             height: 94px;
  //             line-height: 94px;
  //             font-size: 36px;
  //             display: flex;
  //             flex-direction: row;
  //             align-items: center;
  //             justify-content: center;
  //             span:first-child{
  //               position: unset;
  //               top: auto;
  //               width: 80px;
  //               height: 80px;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  @media screen and (min-width: 481px) and (max-width: 900px){
    footer{
      height: 64px;
      nav{
        ul{
          li{
            height: 64px;
            a{
              height: 64px;
              line-height: 64px;
            }
          }
        }
      }
    }
  }
@media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
  footer{
      height: 64px;
      // padding-bottom: 10px;
      nav{
        ul{
          li{
            height: 58px;
            a{
              height: 58px;
              position: relative;
              width: 100%;
              span{
                left: 0px;
                width: 100%;
              }
              span:first-child{
                position: absolute;
                height: 24px;
                width: 100%;
                top: 4px;
                margin-right: 0;
                padding: 1px;
                box-sizing: border-box;
                background-size: 21px;
              }
              .description{
                position: absolute;
                // font-size: 17px;
                line-height: 21px;
                top: 28px;
              }
            }
            .messages{
              .coming_soon_indicator{
                position: absolute;
                height: 30px;
                right: calc(50% - 60px);
                top: 5px;
                background-color: rgba(0, 108, 62, 0.15);
                color: #006C3E;
                font-size: 8px;
                line-height: 15px;
                padding: 0px 5px;
                border-radius: 5px;
                text-transform: uppercase;
                font-weight: 500;
                width: 37px;
              }
            }
          }
        }
      }
    }
}
</style>
